<script setup>
  import { ref, reactive, onMounted } from 'vue'
  const props = defineProps({
    areaX1: { type: Number, required: true },
    areaY1: { type: Number, required: true },
    areaX2: { type: Number, required: true },
    areaY2: { type: Number, required: true },
    areaX3: { type: Number, required: true },
    areaY3: { type: Number, required: true },
    areaX4: { type: Number, required: true },
    areaY4: { type: Number, required: true },
    sensorId: { type: Number, required: true },
    modelName: { type: String, required: true }
  })
  const mouseX = ref(0);
  const mouseY = ref(0);
  const maxImgWidth = 640;
  const maxImgHeight = 480;
  const roomImage = ref(null)
  const drawingCanvas = ref(null)
  const pos1 = reactive({ x: ref(props.areaX1), y: ref(props.areaY1) });
  const pos2 = reactive({ x: ref(props.areaX2), y: ref(props.areaY2) });
  const pos3 = reactive({ x: ref(props.areaX3), y: ref(props.areaY3) });
  const pos4 = reactive({ x: ref(props.areaX4), y: ref(props.areaY4) });
  let positions = [pos1, pos2, pos3, pos4];
  let finalPos1 = null
  let finalPos2 = null
  let finalPos3 = null
  let finalPos4 = null
  const canvasWidth = ref(0);
  const canvasHeight = ref(0);

  const imageUrl = '/sensors/snapshot-' + props.sensorId + '.jpg'

  const handleMouseClick = () => {
    console.log('mouse click');
    if (pos1.x && pos2.x && pos3.x && pos4.x) {
      pos1.x = mouseX.value;
      pos1.y = mouseY.value;
      pos2.x = null
      pos2.y = null
      pos3.x = null
      pos3.y = null
      pos4.x = null
      pos4.y = null
      clearCanvas()
    }
    else if (! pos1.x){
      pos1.x = mouseX.value;
      pos1.y = mouseY.value;
    }
    else if (! pos2.x){
      pos2.x = mouseX.value;
      pos2.y = mouseY.value;
      drawLine(pos1.x, pos1.y, pos2.x, pos2.y);
    }
    else if (! pos3.x){
      pos3.x = mouseX.value;
      pos3.y = mouseY.value;
      drawLine(pos2.x, pos2.y, pos3.x, pos3.y);
    }
    else if (! pos4.x){
      pos4.x = mouseX.value;
      pos4.y = mouseY.value;
      clearCanvas()
      drawFigure()
    }
    
  };


  const handleMouseMove = (event) => {
    const rect = event.target.getBoundingClientRect();
    mouseX.value = event.clientX - rect.left;
    mouseY.value = event.clientY - rect.top;
    if (! pos2.x){
      clearCanvas()
      drawLine(pos1.x, pos1.y, mouseX.value, mouseY.value);
    }
    else if (! pos3.x){
      clearCanvas()
      drawLine(pos1.x, pos1.y, pos2.x, pos2.y);
      drawLine(pos2.x, pos2.y, mouseX.value, mouseY.value);
    }
    else if (! pos4.x){
      clearCanvas()
      drawLine(pos1.x, pos1.y, pos2.x, pos2.y);
      drawLine(pos2.x, pos2.y, pos3.x, pos3.y);
      drawLine(pos3.x, pos3.y, mouseX.value, mouseY.value);
    }
  };

  const handleResize = () => {
    if (roomImage) {
      canvasWidth.value = roomImage.value.clientWidth;
      canvasHeight.value = roomImage.value.clientHeight;
      if (pos1 && pos2 && pos3 && pos4) {
        const coordX1 = (pos1.x/maxImgWidth) * canvasWidth.value
        const coordY1 = (pos1.y/maxImgHeight) * canvasHeight.value
        const coordX2 = (pos2.x/maxImgWidth) * canvasWidth.value
        const coordY2 = (pos2.y/maxImgHeight) * canvasHeight.value
        const coordX3 = (pos3.x/maxImgWidth) * canvasWidth.value
        const coordY3 = (pos3.y/maxImgHeight) * canvasHeight.value
        const coordX4 = (pos4.x/maxImgWidth) * canvasWidth.value
        const coordY4 = (pos4.y/maxImgHeight) * canvasHeight.value
        setTimeout(() => { drawResizedFigure(coordX1, coordY1, coordX2, coordY2, coordX3, coordY3, coordX4, coordY4) }, 200);
      }
    }
  };


  const drawLine = (x1, y1, x2, y2) => {
    const ctx = drawingCanvas.value.getContext("2d");
    ctx.strokeStyle = 'yellow';
    ctx.beginPath();
    ctx.moveTo(x1, y1);
    ctx.lineTo(x2, y2);
    ctx.stroke();
  };

  const drawFigure = () => {
    const ctx = drawingCanvas.value.getContext("2d");
    reorderValues()
    
    ctx.strokeStyle = 'yellow';
    ctx.beginPath();
    ctx.moveTo(finalPos1.x, finalPos1.y);
    ctx.lineTo(finalPos2.x, finalPos2.y);
    ctx.lineTo(finalPos4.x, finalPos4.y);
    ctx.lineTo(finalPos3.x, finalPos3.y);
    ctx.closePath();
    ctx.stroke();
  };

  const drawResizedFigure = (coordX1, coordY1, coordX2, coordY2, coordX3, coordY3, coordX4, coordY4) => {
    clearCanvas()
    const ctx = drawingCanvas.value.getContext("2d");
    ctx.strokeStyle = 'yellow';
    ctx.beginPath();
    ctx.moveTo(coordX1, coordY1);
    ctx.lineTo(coordX2, coordY2);
    ctx.lineTo(coordX4, coordY4);
    ctx.lineTo(coordX3, coordY3);
    ctx.closePath();
    ctx.stroke();
  };

  const clearCanvas = () => {
    const canvas = drawingCanvas.value;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  const reorderValues = () => {
    positions.sort((a, b) => a.y - b.y);
    if (positions[0].x < positions[1].x){
      finalPos1 = positions[0]
      finalPos2 = positions[1]
    } else {
      finalPos1 = positions[1]
      finalPos2 = positions[0]
    }
    if (positions[2].x < positions[3].x){
      finalPos3 = positions[2]
      finalPos4 = positions[3]
    } else {
      finalPos3 = positions[3]
      finalPos4 = positions[2]
    }
  }

  const calculateValueForForm = (areaNumber, areaLetter) => {
    switch (areaNumber) {
      case 1:
        if (finalPos1){
          if (areaLetter == 'x'){
            return Math.round(finalPos1.x/canvasWidth.value * maxImgWidth)
          } else {
            return Math.round(finalPos1.y/canvasWidth.value * maxImgWidth)
          }
        } else {
          if (areaLetter == 'x') {
            return Math.round(pos1.x)
          } else {
            return Math.round(pos1.y)
          }
        }
      case 2:
        if (finalPos2){
          if (areaLetter == 'x'){
            return Math.round(finalPos2.x/canvasWidth.value * maxImgWidth)
          } else {
            return Math.round(finalPos2.y/canvasWidth.value * maxImgWidth)
          }
        } else {
          if (areaLetter == 'x') {
            return Math.round(pos2.x)
          } else {
            return Math.round(pos2.y)
          }
        }
      case 3:
        if (finalPos3){
          if (areaLetter == 'x'){
            return Math.round(finalPos3.x/canvasWidth.value * maxImgWidth)
          } else {
            return Math.round(finalPos3.y/canvasWidth.value * maxImgWidth)
          }
        } else {
          if (areaLetter == 'x') {
            return Math.round(pos3.x)
          } else {
            return Math.round(pos3.y)
          }
        }
      case 4:
        if (finalPos4){
          if (areaLetter == 'x'){
            return Math.round(finalPos4.x/canvasWidth.value * maxImgWidth)
          } else {
            return Math.round(finalPos4.y/canvasWidth.value * maxImgWidth)
          }
        } else {
          if (areaLetter == 'x') {
            return Math.round(pos4.x)
          } else {
            return Math.round(pos4.y)
          }
        }
      default:
        return 0
    }
  }

  onMounted(() => {
    roomImage.value.onload = function (){
      handleResize()
      window.addEventListener('resize', handleResize)
      const coordX1 = (props.areaX1/maxImgWidth) * canvasWidth.value
      const coordY1 = (props.areaY1/maxImgHeight) * canvasHeight.value
      const coordX2 = (props.areaX2/maxImgWidth) * canvasWidth.value
      const coordY2 = (props.areaY2/maxImgHeight) * canvasHeight.value
      const coordX3 = (props.areaX3/maxImgWidth) * canvasWidth.value
      const coordY3 = (props.areaY3/maxImgHeight) * canvasHeight.value
      const coordX4 = (props.areaX4/maxImgWidth) * canvasWidth.value
      const coordY4 = (props.areaY4/maxImgHeight) * canvasHeight.value
      setTimeout(() => { drawResizedFigure(coordX1, coordY1, coordX2, coordY2, coordX3, coordY3, coordX4, coordY4) }, 500);
    }
  });

</script>

<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <div class="canvas-container"
             @mousemove="handleMouseMove"
             @click="handleMouseClick">
          <img :src="imageUrl"
               alt="room-example"
               class="img-fluid"
               style="pointer-events: none;"
               id="room-image"
               ref="roomImage"
          />
          <canvas ref="drawingCanvas"
                  id="drawing-canvas"
                  :width="canvasWidth"
                  :height="canvasHeight"
          ></canvas>
        </div>
      </div>
      <div class="col-md-6" style="visibility: hidden;">
        <div class="row">
          <div class="col-md-6">
            <label class="form-label" :for="`${props.modelName}_area_x1`">Area X1</label>
            <input type="number" :name="`${props.modelName}[area_x1]`" :id="`${props.modelName}_area_x1`" class="form-control mb-3" min="0" :value="calculateValueForForm(1, 'x')">
          </div>
          <div class="col-md-6">
            <label class="form-label" :for="`${props.modelName}_area_y1`">Area Y1</label>
            <input type="number" :name="`${props.modelName}[area_y1]`" :id="`${props.modelName}_area_y1`" class="form-control mb-3" min="0" :value="calculateValueForForm(1, 'y')">
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label class="form-label" :for="`${props.modelName}_area_x2`">Area X2</label>
            <input type="number" :name="`${props.modelName}[area_x2]`" :id="`${props.modelName}_area_x2`" class="form-control mb-3" min="0" :value="calculateValueForForm(2, 'x')">
          </div>
          <div class="col-md-6">
            <label class="form-label" :for="`${props.modelName}_area_y2`">Area Y2</label>
            <input type="number" :name="`${props.modelName}[area_y2]`" :id="`${props.modelName}_area_y2`" class="form-control mb-3" min="0" :value="calculateValueForForm(2, 'y')">
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label class="form-label" :for="`${props.modelName}_area_x3`">Area X3</label>
            <input type="number" :name="`${props.modelName}[area_x3]`" :id="`${props.modelName}_area_x3`" class="form-control mb-3" min="0" :value="calculateValueForForm(3, 'x')">
          </div>
          <div class="col-md-6">
            <label class="form-label" :for="`${props.modelName}_area_y3`">Area Y3</label>
            <input type="number" :name="`${props.modelName}[area_y3]`" :id="`${props.modelName}_area_y3`" class="form-control mb-3" min="0" :value="calculateValueForForm(3, 'y')">
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label class="form-label" :for="`${props.modelName}_area_x4`">Area X4</label>
            <input type="number" :name="`${props.modelName}[area_x4]`" :id="`${props.modelName}_area_x4`" class="form-control mb-3" min="0" :value="calculateValueForForm(4, 'x')">
          </div>
          <div class="col-md-6">
            <label class="form-label" :for="`${props.modelName}_area_y4`">Area Y4</label>
            <input type="number" :name="`${props.modelName}[area_y4]`" :id="`${props.modelName}_area_y4`" class="form-control mb-3" min="0" :value="calculateValueForForm(4, 'y')">
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<style scoped>
.canvas-container {
  position: relative;
  display: inline-block;
}

#drawing-canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1; /* Set z-index to make the canvas appear on top */
}
</style>